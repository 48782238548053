<template>
  <div>
    <el-button v-if="editAuth" style="margin-bottom: 5px" class="addBtn" @click="$refs.editPage.open(0)">
      新增
    </el-button>
    <el-table ref="vmTable" v-loading="loading" :data="tableData">
      <table-column prop="coverImage" label="封面">
        <template #default="scope">
          <img style="width: 80px;height: 80px" alt="" :src="scope.row.coverImage" @click.self="showBigImage($event)"/>
        </template>
      </table-column>
      <table-column prop="contentType" label="资料类型" align="center">
        <template #default="scope">
          <span v-if="scope.row.contentType === 10">
            <el-tag type="success">产品资料</el-tag>
          </span>
          <span v-else>
            <el-tag type="success">视频资料</el-tag>
          </span>
        </template>
      </table-column>
      <table-column prop="status" label="状态" align="center">
        <template #default="scope">
          <span v-if="scope.row.status === 10">
            <el-tag type="info">未发布</el-tag>
          </span>
          <span v-else-if="scope.row.status === 20">
            <el-tag type="success">已发布</el-tag>
          </span>
          <span v-else>
            <el-tag type="warning">作废</el-tag>
          </span>
        </template>
      </table-column>
      <table-column prop="showOrder" label="是否展示" align="center">
        <template #default="scope">
          <span v-if="scope.row.showOrder === 10">
            <el-tag type="success">展示</el-tag>
          </span>
          <span v-else>
            <el-tag type="info">不展示</el-tag>
          </span>
        </template>
      </table-column>
      <table-column v-if="editAuth" :label="$t('common.operation')" :width="100" align="center" fixed="right" :tooltip="false">
        <template #default="scope">
          <el-button type="text" @click="handleEdit(scope.row)">{{ $t("common.edit") }}</el-button>
          <el-button type="text" class="operateDelBtn" @click="handleDelete(scope.row)">
            {{ $t("common.delete") }}
          </el-button>
        </template>
      </table-column>
    </el-table>
    <edit-dialog ref="editPage" @save-success="getList(-1)"></edit-dialog>
    <big-img :visible="photoVisible" :url="bigImgUrl" @closeClick="photoVisible = false"></big-img>
  </div>
</template>
<script>
import EditDialog from "./BannerEdit.vue";
import auth from "@/util/auth";
import BigImg from "@/components/BigImg";

export default {
  components: { EditDialog, BigImg },
  data() {
    return {
      editAuth: this.$auth(835),
      photoVisible: false,
      bigImgUrl: "",
      loading: false,
      tableData: [],
      search: {
        filter: "",
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.$http.get("banners/").then(res => {
        this.tableData = res.data;
        this.loading = false;
      }).catch((error) => {
        this.loading = false;
        if (error.response) {
          this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
        }
      });
    },
    showBigImage(e) {
      //点击图片函数，点击后，把photoVisible设置成true
      if (e !== "") {
        this.photoVisible = true;
        this.bigImgUrl = e.currentTarget.src;
      }
    },
    handleAdd() {
      this.$refs.editPage.open(0);
    },
    handleEdit(row) {
      this.$refs.editPage.open(row.id);
    },
    handleDelete(row) {
      this.$confirm(this.$t("common.isDelete") + "?", this.$t("common.tips"), {
        confirmButtonText: this.$t("common.confirm"),
        cancelButtonText: this.$t("common.cancel"),
        type: "warning",
      }).then(() => {
        this.$http.delete("banners/" + row.id).then(res => {
          this.getList(-1);
          this.$message.success(this.$t("common.tip.deleteSuccess"));
        }).catch(() => {
          this.$message.error(this.$t("common.tip.deleteError"));
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
